import { Injectable, EventEmitter } from '@angular/core';

import { HttpClient, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {
  Router
} from '@angular/router';
import {
  Observable
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Y } from '@angular/cdk/keycodes';

@Injectable({
  providedIn: 'root'
})
export class FacilityServiceService {
  LOGIN = "webapi/login";
  FLAT_COUNT = "webapi/flat_details";
  RECHARGE = "webapi/recharge_details";
  DAILY_CONSUPTION = "webapi/daily_consumption";
  MONTHLY_CONSUMPTION = "webapi/monthly_consumption";

  WATER_CONSUMPTION = 'webapi/water_consumption'
  DG_GRID_CONSUMPTION = 'webapi/dg_grid_consumption';
  LOAD_CONSUMPTION = 'webapi/load_consumption'
  AMR_DATA = 'webapi/amr_data'
  DG_FUEL_LEVEL = 'webapi/dg_fuel_level'
  WEEK_WISE_RECHARGE = 'webapi/week_wise_recharge'
  POWER_SATE = 'webapi/power_state'
  TOWER_POWER_STATUS = 'webapi/power_status'
  TOWER_LOW_BALANCE = 'webapi/low_balance'
  GET_CURRENCY_AND_UNIT = 'webapi/measurement_unit';
  ibmsToken: any;
  DATA_LOGGER = 'webapi/datalogger';

  TOWER_DATA_LOGGER = 'webapi/tower_list';
  TOWER_DIC_LIST = 'webapi/dic_list_tower_wise';
  DIC_LIST = 'webapi/dic_list';
  SENSOR_LIST = 'webapi/sensor_list'

  SENSOR_DETAIL = 'webapi/sensor_details';
  SENSOR_MONTHLY_BILL_DETAIL = 'webapi/sensor_monthly_bill';
  SENSOR_MONTHLY_DETAIL = 'webapi/sensor_monthly_details';
  SENSOR_DAILY_DETAIL = 'webapi/sensor_daily_details';
  GET_LOCATION = '/webapi/location_select'
  RECHARGE_COUPON = '/webapi/recharge'

  /**
   * Reporting Parameter
   */
  RECHARGE_REPORT = '/webapi/report'

  constructor(private _http: HttpClient, private router: Router
    // tslint:disable-next-line: no-shadowed-variable
  ) {

  }
  tokenIddata: any;
  setTokenId() {
    this.tokenIddata = sessionStorage.getItem('tokken');
  }
  get tokenId() {
    return sessionStorage.getItem('tokken');
  }
  setIBMSTOKEN(data) {
    this.ibmsToken = data;
  }
  get IBMSTOKEN() {
    return this.ibmsToken;
  }
  getLocationList(token): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.GET_LOCATION + '?token_id=' + token).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }

  getminmaxdetil(token_id: any, location_id: any): Observable<any> {
    return this._http.get(environment.FACILITY_URL + `webapi/getPayableRelatedData?token_id=${token_id}&location_id=${location_id}`).pipe(map(
      data => {
        return data
      }),
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    )
  }
  setRechargeCoupon(site_id, coupon_id, location_id): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.RECHARGE_COUPON + '?token_id=' + this.tokenId + '&site_id=' + site_id + '&coupon_id=' + coupon_id + '&location_id=' + location_id).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  getLoginList(login_id,token_id,start_date,end_date): Observable<any> {

    return this._http.get(environment.NEW_FACILITY_URL+`api/aipl_login_log?token_id=${token_id}&login_id=${login_id}&from_date=${start_date}&to_date=${end_date}`).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  setRecharge(amount, location_id): Observable<any> {

    return this._http.get(environment.FACILITY_URL + '/webapi/individual_recharge' + '?token_id=' + this.tokenId + '&location_id=' + location_id + '&amount=' + amount).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  setDailyConsumption(data) {
    // let dataJson = {
    //   from_date:this.DatePipe.transform(this.startDate, 'yyyy/MM/dd'),
    //   to_date:this.DatePipe.transform(this.endDate, 'yyyy/MM/dd'),
    //   report:'daily_flat_wise',
    //   format:'pdf',
    //   location_id: this.data.sensor.location_id
    // }
    let url = environment.REPORT_URL + '/webapi/report' + '?token_id=' + this.tokenId + '&location_id=' + data.location_id + '&from_date=' + data.from_date + '&to_date=' + data.to_date + '&report=' + data.report + '&format=' + data.format
    window.open(url, "_blank")

  }
  /**
   * recharge report
   */
  getRechargeReport(fromdate, todate, fromflat, toflat, reportname, format, mode, tokenid) {

    let url = environment.REPORT_URL + this.RECHARGE_REPORT + `?from_date=${fromdate}&to_date=${todate}&token_id=${tokenid}&report=${reportname}&format=${format}&from_flat=${fromflat}&to_flat=${toflat}&mode=${mode}`
    window.open(url, "_blank")
  }
  /**
   * get coupon request report
   */
  getCouponRequestReport(fromdate, todate, format, tokenid) {
    let url = environment.REPORT_URL + `/webapi/report?from_date=${fromdate}&to_date=${todate}&token_id=${tokenid}&report=coupon_request&format=${format}`;
    console.log(url)
    window.open(url, "_blank")
  }
  /**
   * get print coupon report api
   */
  getPrintedCoupon(fromdate, todate, format, tokenid) {
    let url = environment.REPORT_URL + `/webapi/report?from_date=${fromdate}&to_date=${todate}&token_id=${tokenid}&report=printed_coupon&format=${format}`;
    window.open(url, "_blank")
  }
  /*
   *get daily flat wise report
   */
  getdailyflatwise(fromdate, todate, format, tokenid, location_id) {
    let url = environment.REPORT_URL + `/webapi/report?from_date=${fromdate}&to_date=${todate}&report=daily_flat_wise&format=${format}&location_id=${location_id}&token_id=${tokenid}`;
    window.open(url, "_blank ")
  }
  /**
   * get daily date wise
   */
  getdailydatewise(fromlocation, tolocation, format, tokenid, date) {
    let url = environment.REPORT_URL + `/webapi/report?from_flat=${fromlocation}&to_flat=${tolocation}&report=daily_date_wise&format=${format}&date=${date}&token_id=${tokenid}`;
    window.open(url, "_blank ")
  }
  /**
   * gte month flat wise
   */
  getmonthflatwise(fromdate, todate, format, tokenid, locationid, reportname) {
    let url = environment.REPORT_URL + `/webapi/report?from_date=${fromdate}&to_date=${todate}&report=${reportname}&format=${format}&location_id=${locationid}&token_id=${tokenid}`;
    window.open(url, "_blank ")
  }
  /*
   *get month month wise
   */
  getMonthWise(fromlocation, tolocation, format, tokenid, date, reportname) {
    let url = environment.REPORT_URL + `/webapi/report?from_flat=${fromlocation}&to_flat=${tolocation}&report=${reportname}&format=${format}&date=${date}&token_id=${tokenid}`;
    window.open(url, "_blank ")
  }
  /**
   * site load type csv
   */
   getSiteload(format, tokenid) {
    let url = environment.REPORT_URL + `/webapi/report?format=${format}&token_id=${tokenid}&report=site_load_type`;
    window.open(url, "_blank ")
  }
  /**
   * monthly bill
   */
  getMonthlyBill(date, fromflat, toflat, reportname, format, tokenid) {
    let url = environment.REPORT_URL + this.RECHARGE_REPORT + `?date=${date}&token_id=${tokenid}&report=${reportname}&format=${format}&from_flat=${fromflat}&to_flat=${toflat}`
    window.open(url, "_blank")
  }
  sensorDetail(token, id): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.SENSOR_DETAIL + '?token_id=' + token + '&sensor_id=' + id).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  sensorMonthlyBill(token, id): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.SENSOR_MONTHLY_BILL_DETAIL + '?token_id=' + token + '&sensor_id=' + id).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  sensorMonthlyDetail(token, id): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.SENSOR_MONTHLY_DETAIL + '?token_id=' + token + '&sensor_id=' + id).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  sensorDailyDetail(token, id): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.SENSOR_DAILY_DETAIL + '?token_id=' + token + '&sensor_id=' + id).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  sensorList(id): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.SENSOR_LIST + '?token_id=' + this.tokenId + '&dic_id=' + id).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  getDataLogger(): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.DATA_LOGGER + '?token_id=' + this.tokenId).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  getDICList(): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.DIC_LIST + '?token_id=' + this.tokenId).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  getTowerDataLogger(id): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.TOWER_DATA_LOGGER + '?token_id=' + this.tokenId + '&datalogger_id=' + id).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  getTowerDics(dataloggerId, towerid): Observable<any> {
    return this._http.get(environment.FACILITY_URL + this.TOWER_DIC_LIST + '?token_id=' + this.tokenId + '&datalogger_id=' + dataloggerId + '&tower_id=' + towerid).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }

  towerPowerStatus(): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.TOWER_POWER_STATUS + '?token_id=' + this.ibmsToken).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  towerLowBalance(): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.TOWER_LOW_BALANCE + '?token_id=' + this.tokenId).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  getCurrent(): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.GET_CURRENCY_AND_UNIT + '?token_id=' + this.tokenId).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  dgFuelLevel(): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.DG_FUEL_LEVEL + '?token_id=' + this.ibmsToken).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }

  meterStatus(): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.POWER_SATE + '?token_id=' + this.tokenId).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  townWeekWiselLevel(): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.WEEK_WISE_RECHARGE + '?token_id=' + this.tokenId
    ).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  amrDashBoard(): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.AMR_DATA + '?token_id=' + this.tokenId).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  login(loginId, password): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.LOGIN + '?login_id=' + loginId + '&password=' + password).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  flatCount(): Observable<any> {
    return this._http.get(environment.FACILITY_URL + this.FLAT_COUNT + '?token_id=' + this.tokenId).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  Recharge(): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.RECHARGE + '?token_id=' + this.tokenId).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  dailyConsumption(): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.DAILY_CONSUPTION + '?token_id=' + this.tokenId).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  monthlyConsumption(): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.MONTHLY_CONSUMPTION + '?token_id=' + this.tokenId).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  waterConsumption(): Observable<any> {
    return this._http.get(environment.FACILITY_URL + this.WATER_CONSUMPTION + '?token_id=' + this.IBMSTOKEN).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  dgGridConsumption(): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.DG_GRID_CONSUMPTION + '?token_id=' + this.tokenId).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  loadConsumption(): Observable<any> {

    return this._http.get(environment.FACILITY_URL + this.LOAD_CONSUMPTION + '?token_id=' + this.ibmsToken).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }

  getSearchMeter(data): Observable<any> {

    return this._http.get(environment.FACILITY_URL + 'webapi/search_sensor' + '?token_id=' + this.tokenId + '&data=' + data).pipe(
      // eg. "map" without a dot before
      map(data => {
        return data;
      }),
      // "catchError" instead "catch"
      catchError(error => {
        alert("Something went wrong ;)");
        return Observable.throw('Something went wrong ;)');
      })
    );
  }
  /**
   * site details
   */
  getSiteDetials(token_id: any): Observable<any> {
    return this._http.get(environment.FACILITY_URL + `/webapi/site_details?token_id=${token_id}`).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    )
  }
  /**
   * get list data api
   */
  getListData(tokenid: any, name: any): Observable<any> {
    return this._http.get(environment.FACILITY_URL + `/webapi/list_data?token_id=${tokenid}&value=${name}`).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    )
  }
  getLoginIDList(tokenid: any): Observable<any> {
    return this._http.get(environment.NEW_FACILITY_URL + `/api/login_list?token_id=${tokenid}`).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    )
  }
  /**
   * get user list
   */
  getUserList(token_id): Observable<any> {
    return this._http.get(environment.FACILITY_URL + `webapi/user_list?token_id=${token_id}`).pipe(map(data => {
      return data;
    }),
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    )
  }
  /**
   * get order id
   */
  getOrderId(login_id, password, amount): Observable<any> {
    return this._http.get(environment.PAYMENT_URL + `/getOrderID?login_id=${login_id}&password=${password}&amount=${amount}`).pipe(map(data => {
      return data;
    }),
      catchError(error => {
        alert(error)
        return Observable.throw('Something went wrong ;)');
      })
    )
  }
  /**
   * process data
   */
  // getProcess(data:any):Observable<any>{

  //   const headers = { 'content-type': 'application/json'}  
  //     const body=JSON.stringify(data);
  //     console.log(body)
  //     return this._http.post(environment, body,{'headers':headers})

  // }
  dgdetailrunningreport(fromdate: any, todate: any, format: any, token_id: any) {
    let url = `${environment.FACILITY_URL}/webapi/report?from_date=${fromdate}&to_date=${todate}&token_id=${token_id}&report=detailed_dg&format=${format}`;
    window.open(url, "_blank")
  }
  memberdetilsreport(fromlocation, tolocation, format, tokenid) {
    let url = `${environment.FACILITY_URL}/webapi/report?from_flat=${fromlocation}&to_flat=${tolocation}&token_id=${tokenid}&report=member_details&format=${format}`;
    window.open(url, "_blank")
  }
  meterstatus(state, format, tokenid) {
    let url = `${environment.FACILITY_URL}/webapi/report?state=${state}&token_id=${tokenid}&report=meter_status&format=${format}`
    window.open(url, "_blank")
  }
  alarmingBalance(format, tokenid) {
    let url = `${environment.FACILITY_URL}/webapi/report?token_id=${tokenid}&report=alarming_balance&format=${format}`;
    window.open(url, "_blank")
  }
  getdailydatacsv(fromdate, todate, fromlocation, tolocation, tokenid) {
    let url = `${environment.FACILITY_URL}/webapi/report?token_id=${tokenid}&report=daily_data_csv&from_flat=${fromlocation}&to_flat=${tolocation}&from_date=${fromdate}&to_date=${todate}&format=csv`
    window.open(url, "_blank")
  }
  /**
   * api for click and restore
   */
  sendAction(tokenid, sensorid, action): Observable<any> {
    return this._http.get(environment.FACILITY_URL + `/webapi/cut_restore?token_id=${tokenid}&sensor_id=${sensorid}&action=${action}`).pipe(map(data => {
      return data;
    }),
      catchError(error => {
        alert(error)
        return Observable.throw('Something went wrong ;)');
      })
    )
  }
  /**
   * flat debit credit
   */
  flatdrcr(data: any): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this._http.post(environment.FACILITY_URL + `/webapi/dr_cr`, body, { 'headers': headers }).pipe(map(data => {
      return data;
    }),
      catchError(error => {
        alert(error)
        return Observable.throw('Something went wrong ;)');
      })
    )
  }
  /**
   * flat debit list
   */
  getflatdebitcreditlist(fromdate, todate, format, mode, tokenid) {
    let url = environment.REPORT_URL + `/webapi/report?from_date=${fromdate}&to_date=${todate}&token_id=${tokenid}&report=flat_debit_credit&format=${format}&type=${mode}`
    window.open(url, "_blank")
  }
  /**
   * hourly consumption report
   */
   getHourlyConsumptionReport(fromdate, tokenid,locationId) {
    let url = environment.REPORT_URL + `/webapi/report?date=${fromdate}&token_id=${tokenid}&report=hourly_data&format=pdf&location_id=${locationId}`
    window.open(url, "_blank")
  }
  /**
   * generate coupon 
   */
  generateCoupon(tokenid: any, noofcoupon: any, couponamt: any, deductionamt: any): Observable<any> {
    //http://localhost/web_api/webapi/generate_coupon?token_id=7c6d3040849c8fb2466c048cad785742&no_of_coupon=1&coupon_amt=110&deduction_amt=10
    return this._http.get(environment.FACILITY_URL + `/webapi/generate_coupon?token_id=${tokenid}&no_of_coupon=${noofcoupon}&coupon_amt=${couponamt}&deduction_amt=${deductionamt}`).pipe(map(data => {
      return data
    }),
      catchError(error => {
        alert(error)
        return Observable.throw('Something went wrong ;)')
      })
    )
  }
  /**
   * generate coupon report
   */
  generateCouponReport(date1: any, tokenid: any) {
    //http://localhost/web_api/webapi/report?date=2022-03-22&token_id=7c6d3040849c8fb2466c048cad785742&report=print_coupon&format=pdf
    let url = environment.REPORT_URL + `/webapi/report?date=${date1}&token_id=${tokenid}&report=print_coupon&format=pdf`;
    window.open(url, "_blank")
  }
  /**
   * generate individual
   */
  generateIndividual(couponamt, locationno, tokenid) {
    // http://localhost/web_api/webapi/report?coupon_amt=10&location_id=80615080&token_id=7c6d3040849c8fb2466c048cad785742&report=print_receipt&format=pdf
    let url = environment.REPORT_URL + `/webapi/report?coupon_amt=${couponamt}&location_id=${locationno}&token_id=${tokenid}&report=print_receipt&format=pdf`;
    window.open(url, "_blank")
  }
  //http://localhost/web_api/webapi/change_password?token_id=7c6d3040849c8fb2466c048cad785742&old_password=80615362&new_password=80615362
  changepassword(tokenid: any, oldpassword: any, newpassword: any): Observable<any> {
    return this._http.get(environment.FACILITY_URL + `/webapi/change_password?token_id=${tokenid}&old_password=${oldpassword}&new_password=${newpassword}`).pipe(map(data => {
      return data
    }),
      catchError(error => {
        alert(error)
        return Observable.throw('Something went wrong ;)')
      })
    )
  }
  //http://localhost/web_api/webapi/individual_recharge
  generateIndividualNew(tokenid:any,location_id:any,amount:any,deduction_amount:any,document_ref_no:any,recharged_detail:any,receipt_no:any):Observable<any>{
    return this._http.get(environment.FACILITY_URL + `/webapi/individual_recharge?token_id=${tokenid}&location_id=${location_id}&amount=${amount}&deduction_amount=${deduction_amount}&document_ref_no=${document_ref_no}&recharged_detail=${recharged_detail}&receipt_no=${receipt_no}`).pipe(map(data => {
      return data
    }),
      catchError(error => {
        alert(error)
        return Observable.throw('Something went wrong ;)')
      })
    )
  }
  /**
   * online recharge method
   */
  onlineRecharge(data:any){
    return this._http.post(`${environment.PAYMENT_URL}`,data).pipe(map(data => {
      console.log(data)
      return data
    }),
      catchError(error => {
        alert(error)
        return Observable.throw('Something went wrong ;)')
      })
    )
  }
  /**
   * payment url
   */
  paymentCheck(formData:any,action:any){
    return this._http.post(`${action}`,formData).pipe(map(data=>{
      console.log(data)
    }))
  }
}
