import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sensor-dialog',
  templateUrl: './sensor-dialog.component.html',
  styleUrls: ['./sensor-dialog.component.scss']
})
export class SensorDialogComponent implements OnInit {
  dataSource: any;

  constructor( 
    private Router:Router,
    public dialogRef: MatDialogRef<SensorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(JSON.stringify(data))
    }
    ELEMENT_DATA = []
  
    displayedColumns: string[] = ['position', 'meter_sr_no', 'type', 'serial_no','last_reading_updated' ];
   
    @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator | any;
  
    meterOpen(meterId:string){
      this.dialogRef.close()
      sessionStorage.setItem("sensorId",meterId)
      this.Router.navigate(['/dashboard/meter/detail'])


    }
  ngOnInit(): void {
    for (var i = 0; i < this.data.length; i++) {
      this.data[i].position = i + 1;
    }
    this.dataSource = [];

setTimeout(()=>{
  this.dataSource = new MatTableDataSource<any>(this.data);

  this.dataSource.paginator = this.paginator;

},200)
  }

}
