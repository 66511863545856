import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FacilityServiceService } from 'app/views/dashboard/service/facility-service.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  sampleForm: FormGroup;
  tokenid: string;

  constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>,private service:FacilityServiceService,public datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    }

  ngOnInit() {
    this.tokenid = sessionStorage.getItem("tokken")
    this.sampleForm = new FormGroup({
      oldpassword: new FormControl(null,Validators.required),
      newpassword: new FormControl(null,Validators.required)
    });
  }
  closeD(){
    this.dialogRef.close();
  }

  onSubmit(){
    this.service.changepassword(this.tokenid,this.sampleForm.value.oldpassword,this.sampleForm.value.newpassword).subscribe(res=>{
      alert(res.message)
      this.dialogRef.close()
    })
  }

}
