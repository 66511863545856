import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FacilityServiceService } from 'app/views/dashboard/service/facility-service.service';

@Component({
  selector: 'app-online-payment',
  templateUrl: './online-payment.component.html',
  styleUrls: ['./online-payment.component.scss']
})
export class OnlinePaymentComponent implements OnInit {

  reportname: any;
  maxDate=new Date();
  start:any;
  end:any;
  sampleForm: FormGroup;
  fromlocation: any;
  site_id: any;
  tokenid: string;
  tolocation: any;
  dataList: any=[];
  location: any;
  amount: any;
  sensorDetail: any;

  constructor(public dialogRef: MatDialogRef<OnlinePaymentComponent>,private service:FacilityServiceService,public datepipe: DatePipe, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.sensorDetail = data.sensor
    this.fromlocation = data.sensor.location_id
    console.log(this.sensorDetail)
  }

  ngOnInit() {
    this.sampleForm = new FormGroup({
      amount:new FormControl(null,Validators.required)
    });

    this.tokenid = sessionStorage.getItem("tokken")
  }

  onrecharge(){  
    var formData =  new FormData();
    formData.append("amount",this.sampleForm.value.amount)
    formData.append("location_id",this.fromlocation)
    this.service.onlineRecharge(formData).subscribe(res=>{
      var formD1 = new FormData();
      formD1.append("encRequest",res['encRequest'])
      formD1.append("access-code",res['access_code'])
      this.service.paymentCheck(formD1,res['action_url']).subscribe(res=>{})
      //$('body').append($form);
      // document.redirect.submit();
      window.open(res['action_url'])
      
    })
  }

}
