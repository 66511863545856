
import { Component, Inject, OnInit } from "@angular/core";
import { egretAnimations } from "app/shared/animations/egret-animations";
import { ThemeService } from "app/shared/services/theme.service";
import tinyColor from 'tinycolor2';
import * as Highcharts from "highcharts";
import { FacilityServiceService } from '../service/facility-service.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { FormControl, FormGroup } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material";
import { DatePipe } from "@angular/common";
import { ReportDialogueComponent } from "app/report-dialogue/report-dialogue.component";
import { NgxUiLoaderModule, NgxUiLoaderService } from "ngx-ui-loader";
import { FlatdrcrcComponent } from "app/report/flatdrcrc/flatdrcrc.component";
import { OnlinePaymentComponent } from "app/online-payment/online-payment.component";

@Component({
  selector: 'app-meter-detail',
  templateUrl: './meter-detail.component.html',
  styleUrls: ['./meter-detail.component.scss'],
  animations: egretAnimations
}) 
export class MeterDetailComponent implements OnInit {
  cryptoChart: any;
  cryptoDonutChart: any;
  activeTrades: any[];
  lineupdate: boolean = false
  barupdate: boolean = false

  currentRoute : string;

  chargesTrades: any[];
  trendingCurrencies: any[];
  trafficSourcesChart: any;
  monthlyTrafficChartBar: any;
  sensorid: string;
  sensorDetails: any;
  userData: any;
  d: boolean = true;
  buttonname: string;

  buttondisabled : boolean = false;
  id: NodeJS.Timer;
  constructor(
    public dialog: MatDialog,
    private themeService: ThemeService,
    private service: FacilityServiceService,
    private loader:NgxUiLoaderService,
    private Router: Router
  ) {
    this.sensorid = sessionStorage.getItem("sensorId")
    if (this.sensorid) { } else {
      this.Router.navigateByUrl('sessions/Login')
    }
    console.log(this.Router.url)

    // Router.events.filter(event => event instanceof NavigationEnd)
    //       .subscribe(event => 
    //        {
    //           this.currentRoute = event.url;          
    //           console.log(event);
    //        });

    this.sensorDetail();
    this.id = setInterval(() => {
      this.sensorDetailV1(); 
    }, 5000);
  }
  applicationLoadInits;
  dailyInits;
  monthlyBillInits;
  applicationLoad(e) {
    this.applicationLoadInits = e;
  }
  monthlyBillInit(e) {
    this.monthlyBillInits = e;
  }
  dailyInit(e) {
    this.dailyInits = e;
  }
  dailyConsumptionReport() {
    const dialogRef = this.dialog.open(DailyConsumtionDialog, {
      width: '350px',
      data: { sensor: this.sensorDetails }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sensorDetail()
      }
    });
  }
  monthlyConsumptionReport() {
  }
  dailyConsumption() {
    this.service.sensorDailyDetail(this.service.tokenId, this.sensorid).subscribe(res => {
      // linechartOptions
      let date = [];
      console.log("Daily Con" + JSON.stringify(res))
      let dgArray = [];

      for (var i = 0; i < res.resource.sensor.length; i++) {
        date.push(res.resource.sensor[i].date)
        this.linechartOptions.series[0].data.push(res.resource.sensor[i].grid_unit)
        this.linechartOptions.series[1].data.push(res.resource.sensor[i].dg_unit)
      }
      this.linechartOptions.xAxis.categories = date;
      this.lineupdate = true;
      setTimeout(() => {
        this.lineupdate = false;
      }, 100)
      // this.monthConsumption();

    })
  }
  monthConsumption() {
    this.service.sensorMonthlyDetail(this.service.tokenId, this.sensorid).subscribe(res => {
      // alert(JSON.stringify(res))
    })
  }
  monthConsumptionBill() {
    this.service.sensorMonthlyBill(this.service.tokenId, this.sensorid).subscribe(res => {
      let dateArray = [];
      let valueArray = [];
      for (var i = 0; i < res.resource.sensor.length; i++) {
        dateArray.push(res.resource.sensor[i].date)
        valueArray.push(res.resource.sensor[i].consumed_amt)
      }
      this.monthlyTrafficChartBar.xAxis.data = dateArray;
      this.monthlyTrafficChartBar.series[0].data = valueArray;
      this.monthlyTrafficChartBar.series[0].name = "Consumed Amt"
      // this.monthlyBillInits.setOption(this.monthlyTrafficChartBar)
    })
  }

  sensorDetailV1() {
    this.service.sensorDetail(this.service.tokenId, this.sensorid).subscribe(res => {
      this.sensorDetails = res.resource.sensor;
      this.trafficSourcesChart.series[0].data[0].value = this.sensorDetails.dg_load
      this.trafficSourcesChart.series[0].data[1].value = this.sensorDetails.grid_load
      this.applicationLoadInits.setOption(this.trafficSourcesChart);
      console.log(this.sensorDetails.state)
      if(this.sensorDetails.state == 'Power Restore'){
        this.buttonname = 'CUT SUPPLY'
      }
      if(this.sensorDetails.state == 'Power Cut'){
        this.buttonname = 'RESTORE SUPPLY'
      }
      if (this.sensorDetails.active_cut_off == 'Y') {
        this.activeTrades[0].icon = "assets/images/Light.png";
        this.activeTrades[0].value = "Yes";
      } else {
        this.activeTrades[0].icon = "assets/images/Light-off.png";
        this.activeTrades[0].value = "N0";
      }
      this.activeTrades[1].value = 'Rs. ' + this.sensorDetails.active_cut_off_min_balance;
      this.activeTrades[2].value = this.sensorDetails.mobile_no;
      if (this.sensorDetails.notification_sms == 'Y') {
        this.activeTrades[3].value = "Yes"
      } else {
        this.activeTrades[3].value = "No"
      }
      if (this.sensorDetails.notification_email == 'Y') {
        this.activeTrades[4].value = "Yes"
      } else {
        this.activeTrades[4].value = "No"
      }
      this.chargesTrades[0].value = this.sensorDetails.CAM_charges;
      this.chargesTrades[1].value = this.sensorDetails.CS_charges;
      this.chargesTrades[2].value = this.sensorDetails.grid_mmc_charges;
      this.chargesTrades[3].value = this.sensorDetails.dg_mmc_charges;
      this.chargesTrades[4].value = this.sensorDetails.grid_fixed_charges;
      this.chargesTrades[5].value = this.sensorDetails.dg_fixed_charges;
      // this.chargesTrades[6].value = this.sensorDetails.other_charges;
    })
  }

  sensorDetail() {
    this.monthConsumptionBill();
    this.dailyConsumption();
    this.service.sensorDetail(this.service.tokenId, this.sensorid).subscribe(res => {
      this.sensorDetails = res.resource.sensor;
      this.trafficSourcesChart.series[0].data[0].value = this.sensorDetails.dg_load
      this.trafficSourcesChart.series[0].data[1].value = this.sensorDetails.grid_load
      this.applicationLoadInits.setOption(this.trafficSourcesChart);
      console.log(this.sensorDetails.state)
      if(this.sensorDetails.state == 'Power Restore'){
        this.buttonname = 'CUT SUPPLY'
      }
      if(this.sensorDetails.state == 'Power Cut'){
        this.buttonname = 'RESTORE SUPPLY'
      }
      if (this.sensorDetails.active_cut_off == 'Y') {
        this.activeTrades[0].icon = "assets/images/Light.png";
        this.activeTrades[0].value = "Yes";
      } else {
        this.activeTrades[0].icon = "assets/images/Light-off.png";
        this.activeTrades[0].value = "N0";
      }
      this.activeTrades[1].value = 'Rs. ' + this.sensorDetails.active_cut_off_min_balance;
      this.activeTrades[2].value = this.sensorDetails.mobile_no;
      if (this.sensorDetails.notification_sms == 'Y') {
        this.activeTrades[3].value = "Yes"
      } else {
        this.activeTrades[3].value = "No"
      }
      if (this.sensorDetails.notification_email == 'Y') {
        this.activeTrades[4].value = "Yes"
      } else {
        this.activeTrades[4].value = "No"
      }
      this.chargesTrades[0].value = this.sensorDetails.CAM_charges;
      this.chargesTrades[1].value = this.sensorDetails.CS_charges;
      this.chargesTrades[2].value = this.sensorDetails.grid_mmc_charges;
      this.chargesTrades[3].value = this.sensorDetails.dg_mmc_charges;
      this.chargesTrades[4].value = this.sensorDetails.grid_fixed_charges;
      this.chargesTrades[5].value = this.sensorDetails.dg_fixed_charges;
      // this.chargesTrades[6].value = this.sensorDetails.other_charges;
    })
  }
  ngOnInit() {
    this.userData = JSON.parse(sessionStorage.getItem('data'))
    this.d = !this.d
    this.themeService.onThemeChange.subscribe(activeTheme => {
      this.initTrafficSourcesChart(activeTheme)
      this.initCryptoChart(activeTheme);
    });
    this.initCryptoChart(this.themeService.activatedTheme);
    this.initTrafficSourcesChart(this.themeService.activatedTheme)
    this.cryptoDonutChart = {
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      color: ["#03A9F4", "#039BE5", "#fcc02e"],
      tooltip: {
        show: false,
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)"
      },
      xAxis: [
        {
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          }
        }
      ],
      yAxis: [
        {
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          }
        }
      ],

      series: [
        {
          name: "Sessions",
          type: "pie",
          radius: ["65%", "85%"],
          center: ["50%", "50%"],
          avoidLabelOverlap: false,
          hoverOffset: 5,
          stillShowZeroSum: false,
          label: {
            normal: {
              show: false,
              position: "center",
              textStyle: {
                fontSize: "13",
                fontWeight: "normal"
              },
              formatter: "{a}"
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "15",
                fontWeight: "normal",
                color: "rgba(0, 0, 0, 0.8)"
              },
              formatter: "{b} \n{c} ({d}%)"
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: [
            {
              value: 335,
              name: "Direct"
            },
            {
              value: 310,
              name: "Search Eng."
            },
            { value: 148, name: "Social" }
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      ]
    };

    this.monthlyTrafficChartBar = {
      tooltip: {
        trigger: "axis",

        axisPointer: {
          animation: true
        }
      },
      grid: {
        left: "0",
        top: "4%",
        right: "0",
        bottom: "0"
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec"
        ],
        axisLabel: {
          show: false
        },
        axisLine: {
          lineStyle: {
            show: false
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      yAxis: {
        type: "value",

        interval: 50,
        axisLabel: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      series: [
        {
          name: "Bill",
          type: "line",
          smooth: true,
          data: [
            140,
            135,
            95,
            115,
            95,
            126,
            93,
            145,
            115,
            140,
            135,
            95,
            115,
            95,
            126,
            125,
            145,
            115,
            140,
            135,
            95,
            115,
            95,
            126,
            93,
            145,
            115,
            140,
            135,
            95
          ],
          symbolSize: 8,
          showSymbol: false,
          lineStyle: {
            opacity: 0,
            width: 0
          },
          itemStyle: {
            borderColor: "#f6be1a"
          },
          areaStyle: {
            color: "rgb(136, 227, 252)",
            opacity: 1
          }
        }
      ]
    };

    this.activeTrades = [
      {
        icon: "assets/images/Light.png",
        details: "Active Cut Off",
        value: "Yes"
      },
      {
        icon: "assets/images/consumer_balance.png",
        details: "Active Cut Off Min Balance",
        value: "15.00"
      },
      {
        icon: "assets/images/help_request.png",
        details: "Mobile Number",
        value: "987654321"
      },
      {
        icon: "assets/images/alarm.png",
        details: "SMS Notification",
        value: "No"
      },
      {
        icon: "assets/images/cryptocurrencies/AE.png",
        details: "Email Notification",
        value: "Yes"
      }
    ];

    this.chargesTrades = [
      {
        icon: "assets/images/edit.svg",
        details: "CAM Charges",
        value: "15.00"
      },
      {
        icon: "assets/images/edit.svg",
        details: "CS Charges",
        value: "15.00"
      },
      {
        icon: "assets/images/edit.svg",
        details: "Grid MMC Charges",
        value: "15.00"
      },
      {
        icon: "assets/images/edit.svg",
        details: "DG MMC Charges",
        value: "15.00"
      },
      {
        icon: "assets/images/edit.svg",
        details: "Grid Fixed Charges",
        value: "15.00"
      },
      {
        icon: "assets/images/edit.svg",
        details: "Other Charges",
        value: "15.00"
      }
    ];

    this.trendingCurrencies = [
      {
        details: "Active Cut Off",
        value: "Yes"
      },
      {
        details: "Active Cut Off Min Balance",
        value: 100
      },
      {
        details: "Mobile Number",
        value: 3800
      },
      {
        details: "SMS Notification",
        value: "No"
      },
      {
        details: "Email Notification",
        value: "Yes"
      }
    ];
  }
  /**
   * destroy 
   */
   ngOnDestroy() {
    clearInterval(this.id)
  }

  initTrafficSourcesChart(theme) {
    this.trafficSourcesChart = {
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      color: [
        '#FAE8AC', '#B4E39A',
        tinyColor(theme.baseColor).setAlpha(.8).toString()
      ],
      tooltip: {
        show: false,
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)"
      },
      xAxis: [
        {
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          }
        }
      ],
      yAxis: [
        {
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          }
        }
      ],

      series: [
        {
          name: "Load",
          type: "pie",
          radius: ["55%", "85%"],
          center: ["50%", "50%"],
          avoidLabelOverlap: true,
          hoverOffset: 5,
          stillShowZeroSum: false,
          label: {
            normal: {
              show: true,
              position: "top",
              color: "black",
              textStyle: {
                fontSize: "13",
                fontWeight: "normal"
              },
              formatter: "{a}"
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "15",
                fontWeight: "normal",
                color: "rgba(15, 21, 77, 1)"
              },
              formatter: "{b} \n{c}"
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: [
            {
              value: 5,
              name: "DG Load"
            },
            {
              value: 4,
              name: "Grid Load"
            }

          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      ]
    };
  }

  photos = [{
    name: 'Photo 1',
    url: 'assets/images/sq-15.jpg'
  }, {
    name: 'Photo 2',
    url: 'assets/images/sq-8.jpg'
  }, {
    name: 'Photo 3',
    url: 'assets/images/sq-9.jpg'
  }, {
    name: 'Photo 4',
    url: 'assets/images/sq-10.jpg'
  }, {
    name: 'Photo 5',
    url: 'assets/images/sq-11.jpg'
  }, {
    name: 'Photo 6',
    url: 'assets/images/sq-12.jpg'
  }]


  initCryptoChart(theme) {
    this.cryptoChart = {
      tooltip: {
        show: true,
        trigger: "axis",
        backgroundColor: "#fff",
        extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); color: #444",
        axisPointer: {
          type: "line",
          animation: true
        }
      },
      grid: {
        top: "10%",
        left: "60",
        right: "20",
        bottom: "60"
      },
      xAxis: {
        type: "category",
        data: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30"
        ],
        axisLine: {
          show: false
        },
        axisLabel: {
          show: true,
          margin: 30,
          color: "#888"
        },
        axisTick: {
          show: false
        }
      },
      yAxis: {
        type: "value",
        axisLine: {
          show: false
        },
        axisLabel: {
          show: true,
          margin: 20,
          color: "#888"
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed"
          }
        }
      },
      series: [
        {
          data: [
            640,
            1040,
            840,
            1240,
            1040,
            1440,
            1240,
            1640,
            1440,
            1840,
            1640,
            2040,
            1840,
            2240,
            2040,
            2440,
            2240,
            2640,
            2440,
            2840,
            2640,
            3040,
            2840,
            3240,
            3040,
            3440,
            3240,
            3640,
            3440,
            3840
          ],
          type: "line",
          name: "Grid",
          smooth: true,
          color: tinyColor(theme.baseColor).toString(),
          lineStyle: {
            opacity: 1,
            width: 3
          },
          itemStyle: {
            opacity: 0
          },
          emphasis: {
            itemStyle: {
              color: tinyColor(theme.baseColor).toString(),
              borderColor: tinyColor(theme.baseColor).setAlpha(.4).toString(),
              opacity: 1,
              borderWidth: 8
            },
            label: {
              show: false,
              backgroundColor: "#fff"
            }
          }
        },
        {
          data: [
            240,
            640,
            440,
            840,
            640,
            1040,
            840,
            1240,
            1040,
            1440,
            1240,
            1640,
            1440,
            1840,
            1640,
            2040,
            1840,
            2240,
            2040,
            2440,
            2240,
            2640,
            2440,
            2840,
            2640,
            3040,
            2840,
            3240,
            3040,
            3440
          ],
          type: "line",
          name: "DG",
          smooth: true,
          color: "rgba(0, 0, 0, .3)",
          lineStyle: {
            opacity: 1,
            width: 3
          },
          itemStyle: {
            opacity: 0
          },
          emphasis: {
            itemStyle: {
              color: "rgba(0, 0, 0, .5)",
              borderColor: "rgba(0, 0, 0, .2)",
              opacity: 1,
              borderWidth: 8
            },
            label: {
              show: false,
              backgroundColor: "#fff"
            }
          }
        }
      ]
    };
  }

  highcharts = Highcharts;
  linechartOptions = {
    chart: {
      type: "spline"
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      categories: []
    },
    yAxis: {
      title: {
        text: ""
      }
    },
    tooltip: {
      valueSuffix: " "
    },
    series: [{
      name: 'Grid',
      data: []
    },
    {
      name: 'DG',
      data: []
    }]
  };

  // barchartOptions = {
  //   chart: {
  //     type: 'column'
  //   },
  //   title: {
  //     text: ''
  //   },
  //   subtitle: {
  //     text: ''
  //   },
  //   xAxis: {
  //     categories: [
  //     ],
  //     crosshair: true
  //   },
  //   yAxis: {
  //     min: 0,
  //     title: {
  //       text: ''
  //     }
  //   },
  //   tooltip: {
  //     headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
  //     pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
  //       '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
  //     footerFormat: '</table>',
  //     shared: true,
  //     useHTML: true
  //   },
  //   plotOptions: {
  //     column: {
  //       pointPadding: 0.2,
  //       borderWidth: 0
  //     }
  //   },
  //   series: [{
  //     name: 'Grid',
  //     data: []

  //   }, {
  //     name: 'DG',
  //     data: []

  //   }]
  // };
  /**
   * function for showing report
   */
  showReport(reportname) {
    let dialogRef
    if(reportname == 'recharge'){
      dialogRef = this.dialog.open(ReportDialogueComponent, {
        width: '400px',
        data: {reportname:'RechargeReport',sensor: this.sensorDetails}
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    else if(reportname == 'monthlybill'){
      dialogRef = this.dialog.open(ReportDialogueComponent, {
        width: '400px',
        data: {reportname:'Monthly Bill',sensor: this.sensorDetails}
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }
  /**
   * function for send action
   */
  sendAction(){
    let action ;
    if(this.buttonname == 'CUT SUPPLY'){
      action = 'CUT'
    }
    else{
      action = 'RESTORE'
    }
    this.loader.start()
    this.service.sendAction(sessionStorage.getItem("tokken"),sessionStorage.getItem("sensorId"),action).subscribe(res=>{
      this.loader.stop()
      alert(res.message)
      
    })
    // this.service.sendAction().subscribe()
  }
  /**
   * function for hdfc recharge
   */
  onlinePayment(){
    const dialogRef = this.dialog.open(OnlinePaymentComponent, {
      width: '350px',
      data: { sensor: this.sensorDetails }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.sensorDetail()
      }
    });
  }
   
  // this.sensorDetails
  couponRecharge() {
    const dialogRef = this.dialog.open(RechargeCouponExampleDialog, {
      width: '350px',
      data: { sensor: this.sensorDetails }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.sensorDetail()
      }
    });
  }
  Recharge() {
    const dialogRef = this.dialog.open(RechargeExampleDialog, {
      width: '350px',
      data: { sensor: this.sensorDetails }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sensorDetail()
      }
    });
  }
  /**
   * function for debit credit
   */
  drcr(){
    const dialogRef = this.dialog.open(FlatdrcrcComponent, {
      width: '350px',
      data: { sensor: this.sensorDetails }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sensorDetail()
      }
    });
  }
}

@Component({
  selector: 'couponrecharge-dialog',
  templateUrl: 'couponrecharge-dialog.html',
})
export class RechargeCouponExampleDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RechargeCouponExampleDialog>, @Inject(MAT_DIALOG_DATA) public data: any
    , private service: FacilityServiceService,private loader:NgxUiLoaderService) { }
  userData: any;
  ngOnInit(): void {
  }
  Recharge() {

  }
  recharge: any;
  onNoClick(): void {
    this.dialogRef.close();
  }
  couponRecharge() {
    this.loader.start()
    this.service.setRechargeCoupon(this.data.sensor.site_id, this.recharge, this.data.sensor.location_id).subscribe(res => {
      alert(res.message)
      if (res.rc == 0) {
        this.dialogRef.close(true)
      }
      this.loader.stop()
    });
  }
}
/**
 * function for drcr
 */
@Component({
  selector: 'recharge-dialog',
  templateUrl: 'recharge-dialog.html',
})
export class RechargeExampleDialog implements OnInit {
  tokenid: string;

  constructor(
    public dialogRef: MatDialogRef<RechargeExampleDialog>, @Inject(MAT_DIALOG_DATA) public data: any
    , private service: FacilityServiceService,private loader:NgxUiLoaderService) { }
  userData: any;
  ngOnInit(): void {
    console.log(JSON.stringify(this.data))
    this.tokenid =  sessionStorage.getItem("tokken")
  }

  recharge: any;
  deduction_amount:any;
  payment_reference:any;
  payment_mode:any;
  description:any;
  onNoClick(): void {
    this.dialogRef.close();
  }

  Recharge() {
    this.loader.start()
    this.service.generateIndividualNew(this.tokenid,this.data.sensor.location_id,this.recharge,this.deduction_amount,this.payment_reference,this.payment_mode,this.description).subscribe(res => {
      alert(res.message)
      if (res.rc == 0) {
        this.dialogRef.close(true)
        this.service.generateIndividual(this.recharge,this.data.sensor.location_id,this.tokenid)
      }
      this.loader.stop()
    });
  }
}

@Component({
  selector: 'dailyconsumption-dialog',
  templateUrl: 'report/dailyconsumptionreport.html',
})
export class DailyConsumtionDialog implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DailyConsumtionDialog>, @Inject(MAT_DIALOG_DATA) public data: any
    , private service: FacilityServiceService, private DatePipe: DatePipe) { }
  maxDate: any = new Date();
  startDate: any;
  endDate: any;

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value
    if (this.endDate) {

      if (new Date(this.startDate).getTime() > new Date(this.endDate).getTime()) {

        this.endDate = this.startDate;
      }
    }

  }
  endEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value
  }
  userData: any;
  ngOnInit(): void {
  }

  recharge: any;
  onNoClick(): void {
    this.dialogRef.close();
  }


  Recharge() {
    if (this.startDate && this.endDate) {

    } else {
      alert("Both Input Required")
    }
    //from_date=2019-06-01&to_date=2019-06-20&token_id=dcbae7156974a42fc118492d81fbb653&report=daily_flat_wise&format=pdf&location_id=80615362
    let dataJson = {
      from_date: this.DatePipe.transform(this.startDate, 'yyyy-MM-dd'),
      to_date: this.DatePipe.transform(this.endDate, 'yyyy-MM-dd'),
      report: 'daily_flat_wise',
      format: 'pdf',
      location_id: this.data.sensor.location_id
    }
    this.service.setDailyConsumption(dataJson);
  }
  
}
