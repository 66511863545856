import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { AboutusComponent } from './info/aboutus/aboutus.component';
import { DisclaimerComponent } from './info/disclaimer/disclaimer.component';
import { PrivacyPolicyComponent } from './info/privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './info/refund-policy/refund-policy.component';
import { TermsConditionsComponent } from './info/terms-conditions/terms-conditions.component';
import { ContactComponent } from './info/contact/contact.component';

export const rootRouterConfig: Routes = [
  {
    path:'aboutus',
    component:AboutusComponent,
    data:{title:'AIPL'}
  },
  {
    path:'disclaimer',
    component:DisclaimerComponent,
    data:{title:'AIPL'}
  },
  {
    path:'privacypolicy',
    component:PrivacyPolicyComponent,
    data:{title:'AIPL'}
  },
  {
    path:'refundpolicy',
    component:RefundPolicyComponent,
    data:{title:'AIPL'}
  },
  {
    path:'termsconditions',
    component:TermsConditionsComponent,
    data:{title:'AIPL'}
  },
  {
    path:'contact',
    component:ContactComponent,
    data:{title:'AIPL'}
  },
  {
    path: '',
    redirectTo: 'sessions/Login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      }
    ]
  },

  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'DASHBOARD' }
      },
      /**
  * load reports as a lazy load
  */
      {
        path: '',
        component: AuthLayoutComponent,
        children: [
          {
            path: 'Report',
            loadChildren: () => import('./report/report/report.module').then(m => m.ReportModule),
            data: { title: 'Report', breadcrumb: 'REPORT' }
          }
        ]
      }
    ],

  }
];

