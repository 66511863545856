import { Component, Inject, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FacilityServiceService } from 'app/views/dashboard/service/facility-service.service';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-flatdrcrc',
  templateUrl: './flatdrcrc.component.html',
  styleUrls: ['./flatdrcrc.component.scss']
})
export class FlatdrcrcComponent implements OnInit {
  reportname: any;
  maxDate=new Date();
  start:any;
  end:any;
  sampleForm: FormGroup;
  fromlocation: any;
  site_id: any;
  tokenid: string;
  tolocation: any;
  dataList: any=[];
  sensorData: any={};

  constructor(public dialogRef: MatDialogRef<FlatdrcrcComponent>,private service:FacilityServiceService,public datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,private loader:NgxUiLoaderService) {
      this.sensorData = (data.sensor)
     }

  ngOnInit() {
    this.sampleForm = new FormGroup({
      format:new FormControl(null,Validators.required),
      amount:new FormControl(null,Validators.required),
      desc:new FormControl(null,Validators.required),
      action:new FormControl(null,Validators.required)
    });
    this.tokenid = sessionStorage.getItem("tokken")
  }
  onSubmit(){
    // console.log("data",this.sensorData)
    let data = {
      "token_id":this.tokenid,
      "location_id":this.sensorData.location_id,
      "amount":this.sampleForm.value.amount,
      "action":this.sampleForm.value.action,
      "type":this.sampleForm.value.format,
      "description":this.sampleForm.value.desc}
    this.loader.start()
    this.service.flatdrcr(data).subscribe(res=>{
      alert(res.message)
      this.dialogRef.close()
      this.loader.stop()
    })
  }
  
}
