import { Component, OnInit, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FacilityServiceService } from 'app/views/dashboard/service/facility-service.service';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { ChangePasswordComponent } from 'app/change-password/change-password.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html',  styleUrls: ["./header.scss"],
  animations: egretAnimations
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  public availableLangs = [{
    name: 'EN',
    code: 'en',
    flag: 'flag-icon-us'
  }, {
    name: 'ES',
    code: 'es',
    flag: 'flag-icon-es'
  }]
  currentLang = this.availableLangs[0];

  public egretThemes;
  public layoutConf:any;
  options: any;
  loginType: any;
  url: string;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private Router:Router,
    private service: FacilityServiceService,
    private dialog: MatDialog
  ) {}
  logout(){
    sessionStorage.clear();
    this.Router.navigateByUrl('sessions/Login')
  }
  ngOnInit() {
    if(this.Router.url == 'dashboard/meter/detail'){
      this.url = 'Y'
    }
    else{
      this.url = 'N'
    }
    console.log(this.url)
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.loginType=JSON.parse(sessionStorage.getItem("data"))
    this.translate.use(this.currentLang.code);
  }
  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }
  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  toggleCollapse() {
    // compact --> full
    if(this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
        sidebarCompactToggle: false
      }, {transitionClass: true})
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact',
      sidebarCompactToggle: true
    }, {transitionClass: true})

  }

  onSearch(e) {
    //   console.log(e)
  }
  /**
   * changepassword popup
   */
  Changepassword(){
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '350px',
      data: {  }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        
      }
    });
  }

  meterNo: any;
  getMeterSearch() {

    this.service.getSearchMeter(this.meterNo).subscribe(res => {
      
      if(res.rc == '-1'){
        alert("No Data Found !")
      }
      else{
        this.options = res.data;
      }
    })
  }
  selectmeter() {
    if(this.Router.url == 'dashboard/meter/detail'){
      this.url = 'Y'
    }
    else{
      this.url = 'N'
    }
    sessionStorage.setItem("sensorId", this.meterNo.id)
    this.meterNo = this.meterNo.name
    //window.open('http://localhost:4200/dashboard/meter/detail')
    this.Router.navigate(['/dashboard/meter/detail'])
  }

}