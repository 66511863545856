// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiURL: 'developmentApi',
  LOCAL_BASE:' http://localhost:8080/',
  FACILITY_URL:'https://strataservices.in/web_api/',
  //FACILITY_URL:'https://vapt-feeder.myxenius.com/web_api/',
  NEW_FACILITY_URL:'https://strataservices.in/aipl_api/',
  PAYMENT_URL:'https://vapt-feeder.myxenius.com/Pg_responseController/process_icici',
  REPORT_URL:'https://strataservices.in/web_api'
  //REPORT_URL:'https://vapt-feeder.myxenius.com/web_api'
};
