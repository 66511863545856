import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FacilityServiceService } from 'app/views/dashboard/service/facility-service.service';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMMM YYYY', 
  },
  display: {
    dateInput: 'MMMM YYYY', // this is the format showing on the input element
    monthYearLabel: 'MMMM YYYY', // this is showing on the calendar 
  },
};

@Component({
  selector: 'app-report-dialogue',
  templateUrl: './report-dialogue.component.html',
  styleUrls: ['./report-dialogue.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ReportDialogueComponent implements OnInit {
  reportname: any;
  maxDate=new Date();
  start:any;
  end:any;
  sampleForm: FormGroup;
  sampleForm1: FormGroup;
  fromlocation: any;
  site_id: any;
  tokenid: string;
  tolocation: any;
  constructor(public dialogRef: MatDialogRef<ReportDialogueComponent>,private service:FacilityServiceService,public datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      console.log(data)
      this.reportname = data.reportname
      this.site_id = data.sensor.site_id
      this.fromlocation = data.sensor.location_id
      this.tolocation = data.sensor.location_id
      this.tokenid = sessionStorage.getItem('tokken')
    }

  ngOnInit() {
    this.sampleForm = new FormGroup({
      start: new FormControl(null,Validators.required),
      end: new FormControl(null,Validators.required),
      format:new FormControl(null,Validators.required),
      mode:new FormControl(null,Validators.required)
    });
    this.sampleForm1 = new FormGroup({
      CalMonth: new FormControl(moment()),
      format:new FormControl(null,Validators.required),
    })

  }
  onCancel(){
    this.dialogRef.close();
  }
  onSubmit(){
    if(this.reportname == 'RechargeReport'){
      this.service.getRechargeReport(this.datepipe.transform((this.sampleForm.value.start),'yyyy-MM-dd'),this.datepipe.transform((this.sampleForm.value.end),'yyyy-MM-dd'),this.fromlocation,this.tolocation,'recharge_coupon',this.sampleForm.value.format,this.sampleForm.value.mode,this.tokenid)
    }
    else if(this.reportname == 'Monthly Bill'){
      this.service.getMonthlyBill((this.datepipe.transform((this.sampleForm1.value.CalMonth._d),'yyyy-MM')),this.fromlocation,this.tolocation,'monthly_bill',this.sampleForm1.value.format,this.tokenid)
    }
  }

}
