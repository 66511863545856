import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MatFormFieldModule} from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { 
  PerfectScrollbarModule, 
  PERFECT_SCROLLBAR_CONFIG, 
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';


import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { SensorDialogComponent } from './sensor-dialog/sensor-dialog.component';
import { SharedMaterialModule } from './shared/shared-material.module';
import { DailyConsumtionDialog,  RechargeCouponExampleDialog, RechargeExampleDialog } from './views/dashboard/meter-detail/meter-detail.component';
import { DatePipe } from '@angular/common';
import { NgMonthPickerModule } from 'ng-month-picker';
import { ReportDialogueComponent } from './report-dialogue/report-dialogue.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule} from '@angular/material/input';
import { FlatdrcrcComponent } from './report/flatdrcrc/flatdrcrc.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OnlinePaymentComponent } from './online-payment/online-payment.component';
import { PaymentresponseComponent } from './paymentresponse/paymentresponse.component';

import { AboutusComponent } from './info/aboutus/aboutus.component';
import { DisclaimerComponent } from './info/disclaimer/disclaimer.component';
import { PrivacyPolicyComponent } from './info/privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './info/refund-policy/refund-policy.component';
import { TermsConditionsComponent } from './info/terms-conditions/terms-conditions.component';
import { ContactComponent } from './info/contact/contact.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [SharedMaterialModule,
    BrowserModule,LoadingBarHttpClientModule,LoadingBarRouterModule,
    BrowserAnimationsModule,NgMonthPickerModule,
    SharedModule,
    HttpClientModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true}),
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent, SensorDialogComponent,ReportDialogueComponent,RechargeCouponExampleDialog,RechargeExampleDialog,DailyConsumtionDialog, ReportDialogueComponent, FlatdrcrcComponent, ChangePasswordComponent, OnlinePaymentComponent, PaymentresponseComponent,AboutusComponent, DisclaimerComponent, PrivacyPolicyComponent, RefundPolicyComponent, TermsConditionsComponent, ContactComponent],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },DatePipe,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
  ],
  bootstrap: [AppComponent],
  entryComponents:[SensorDialogComponent,ReportDialogueComponent,RechargeCouponExampleDialog,RechargeExampleDialog,DailyConsumtionDialog,FlatdrcrcComponent,ChangePasswordComponent,OnlinePaymentComponent]
})
export class AppModule { }